import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import { Page } from "../../shared/Page/Page"
import { H1, H2 } from "../../shared/Ui/Typography/Typography"
import { Link } from "gatsby"
import { TextButton } from "../../shared/Ui/TextButton/TextButton"

const Container = styled.div`
    padding: 100rem 0;

    section.features {
        display: flex;
        flex-wrap: wrap;
        gap: 15rem;

        a {
            flex: 1 25%;
            display: flex;
            min-width: 300rem;
        }

        .feature {
            flex: 1;
            display: flex;
            gap: 35rem;

            padding: 30rem 35rem;
            border-radius: 10rem;
            background: ${({ theme }) => theme.surfaces.lighter};
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            transition: all ease-in-out 150ms;
            outline: none;
            overflow: hidden;

            &:hover {
                .title {
                    color: ${({ theme }) => theme.primary}!important;
                }
            }

            svg {
                width: 30rem;
                height: 30rem;
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 5rem;

                > .title {
                    font-family: "Gilroy-SemiBold", sans-serif;
                    color: ${({ theme }) => theme.text.dark};
                    transition: all ease-in-out 150ms;
                }

                > .description {
                    color: ${({ theme }) => theme.text.lighter};
                }
            }
        }
    }

    .more-banner {
        margin: 60rem 0 30rem;
    }

    section.more-features {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 15rem;

        a {
            flex: 0;
            width: 100%;
            display: flex;
            min-width: 300rem;
            max-width: 300rem;
        }

        .feature {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 35rem;

            cursor: pointer;
            padding: 30rem 35rem;
            border-radius: 10rem;
            background: ${({ theme }) => theme.surfaces.lighter};
            transition: all ease-in-out 150ms;
            outline: none;
            overflow: hidden;
            box-shadow: rgba(0, 0, 0, 0.1) 0 4rem 12rem;

            &:hover {
                box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;

                button {
                    color: ${({ theme }) => theme.primary};
                }
            }

            svg {
                width: 30rem;
                height: 30rem;
            }

            button {
                width: 100%;
                font-family: "Gilroy-SemiBold", sans-serif;
                color: ${({ theme }) => theme.text.light};
                gap: 20rem;
            }
        }
    }
`

const Features = () => {
    return (
        <Page constrained>
            <Helmet>
                <title>Features</title>
            </Helmet>
            <Container>
                <section className="header">
                    <H1>Feature packed</H1>
                    <H2>Explore everything we have to offer</H2>
                </section>

                <section className="features">
                    <Link to={`/features/phone-calls`} tabIndex={-1}>
                        <div className="feature">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#featureGradient)"
                                >
                                    <path d="M12.88 5.05c3.18.4 5.67 2.89 6.07 6.07.06.51.49.88.99.88.04 0 .08 0 .12-.01.55-.07.94-.57.87-1.12-.51-4.09-3.72-7.3-7.81-7.81-.55-.06-1.05.33-1.11.88-.07.55.32 1.05.87 1.11zm.38 2.11c-.53-.14-1.08.18-1.22.72s.18 1.08.72 1.22c1.05.27 1.87 1.09 2.15 2.15.12.45.52.75.97.75.08 0 .17-.01.25-.03.53-.14.85-.69.72-1.22-.47-1.77-1.84-3.14-3.59-3.59zm5.97 8.1l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z" />
                                </svg>
                            </div>
                            <div className={"text"}>
                                <div className={"title"}>Beautiful calling</div>
                                <div className={"description"}>
                                    The premiere solution for making and
                                    receiving calls
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={`/features/call-scripts`} tabIndex={-1}>
                        <div className="feature">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#featureGradient)"
                                >
                                    <path d="M3,6C2.45,6,2,5.55,2,5V2c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1S6.55,3,6,3H4v2C4,5.55,3.55,6,3,6z M17,2 c0,0.55,0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1h-3C17.45,1,17,1.45,17,2z M3,18c-0.55,0-1,0.45-1,1v3 c0,0.55,0.45,1,1,1h3c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1H4v-2C4,18.45,3.55,18,3,18z M17,22c0,0.55,0.45,1,1,1h3 c0.55,0,1-0.45,1-1v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2C17.45,21,17,21.45,17,22z M19,18c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V6 c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2V18z M9,9c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,8,9,8.45,9,9z M9,12c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,11,9,11.45,9,12z M9,15c0,0.55,0.45,1,1,1h4 c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,14,9,14.45,9,15z" />
                                </svg>
                            </div>
                            <div className={"text"}>
                                <div className={"title"}>Scripts</div>
                                <div className={"description"}>
                                    Add interactive and dynamic scripts into
                                    your calls
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={`/features/team-sms`} tabIndex={-1}>
                        <div className="feature">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#featureGradient)"
                                >
                                    <path d="M20 6h-1v8c0 .55-.45 1-1 1H6v1c0 1.1.9 2 2 2h10l4 4V8c0-1.1-.9-2-2-2zm-3 5V4c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v13l4-4h9c1.1 0 2-.9 2-2z" />
                                </svg>
                            </div>
                            <div className={"text"}>
                                <div className={"title"}>Team SMS</div>
                                <div className={"description"}>
                                    Integrated two-way chat with your contacts
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={`/features/phone-numbers`} tabIndex={-1}>
                        <div className="feature">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#featureGradient)"
                                >
                                    <g>
                                        <path d="M20,9L20,9c0-0.55-0.45-1-1-1h-3V5c0-0.55-0.45-1-1-1h0c-0.55,0-1,0.45-1,1v3h-4V5c0-0.55-0.45-1-1-1h0C8.45,4,8,4.45,8,5 v3H5C4.45,8,4,8.45,4,9v0c0,0.55,0.45,1,1,1h3v4H5c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h3v3c0,0.55,0.45,1,1,1h0 c0.55,0,1-0.45,1-1v-3h4v3c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-3h3c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-3v-4h3 C19.55,10,20,9.55,20,9z M14,14h-4v-4h4V14z" />
                                    </g>
                                </svg>
                            </div>
                            <div className={"text"}>
                                <div className={"title"}>Phone Numbers</div>
                                <div className={"description"}>
                                    Find and use phone numbers from over 30
                                    countries
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={`/features/in-browser`} tabIndex={-1}>
                        <div className="feature">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    enableBackground="new 0 0 24 24"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#featureGradient)"
                                >
                                    <path d="M15,3c0.55,0,1,0.45,1,1v6h1.59c0.89,0,1.34,1.08,0.71,1.71l-2.59,2.59c-0.39,0.39-1.02,0.39-1.41,0l-2.59-2.59 C11.08,11.08,11.52,10,12.41,10H14V4C14,3.45,14.45,3,15,3z M6,19.59C6,20.37,6.63,21,7.41,21h9.17c0.78,0,1.41-0.63,1.41-1.41 c0-0.72-0.44-1.03-1-1.59h3c1.1,0,2-0.9,2-2v-2c0-0.55-0.45-1-1-1s-1,0.45-1,1v2H4V5l7,0c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1L4,3 C2.9,3,2,3.9,2,5v11c0,1.1,0.9,2,2,2h3C6.45,18.55,6,18.87,6,19.59z" />
                                </svg>
                            </div>
                            <div className={"text"}>
                                <div className={"title"}>In Browser</div>
                                <div className={"description"}>
                                    No downloads or configuration, it's all on
                                    the web
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={`/collaboration/analytics`} tabIndex={-1}>
                        <div className="feature">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#teamGradient)"
                                >
                                    <g>
                                        <g>
                                            <path d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z" />
                                            <polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93" />
                                            <polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className={"text"}>
                                <div className={"title"}>Analytics</div>
                                <div className={"description"}>
                                    Dive into insights for the call flow of your
                                    team
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link
                        to={`/collaboration/contact-management`}
                        tabIndex={-1}
                    >
                        <div className="feature">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#grad1)"
                                >
                                    <defs>
                                        <linearGradient
                                            id="grad1"
                                            x1="50%"
                                            y1="30%"
                                            x2="100%"
                                            y2="100%"
                                        >
                                            <stop
                                                offset="0%"
                                                stopColor={"#8B5CF6"}
                                            />
                                            <stop
                                                offset="100%"
                                                stopColor={"#6366F1"}
                                            />
                                        </linearGradient>
                                    </defs>
                                    <path d="M19 0H5c-.55 0-1 .45-1 1s.45 1 1 1h14c.55 0 1-.45 1-1s-.45-1-1-1zM5 24h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1zM20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 2.75c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25S9.75 10.24 9.75 9 10.76 6.75 12 6.75zM17 17H7v-1.5c0-1.67 3.33-2.5 5-2.5s5 .83 5 2.5V17z" />
                                </svg>
                            </div>
                            <div className={"text"}>
                                <div className={"title"}>
                                    Contact Management
                                </div>
                                <div className={"description"}>
                                    A powerful built-in CRM for managing your
                                    data
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={`/collaboration/tasks-planning`} tabIndex={-1}>
                        <div className="feature">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#grad1)"
                                >
                                    <defs>
                                        <linearGradient
                                            id="grad1"
                                            x1="50%"
                                            y1="0%"
                                            x2="100%"
                                            y2="100%"
                                        >
                                            <stop
                                                offset="0%"
                                                stopColor={"#8B5CF6"}
                                            />
                                            <stop
                                                offset="100%"
                                                stopColor={"#6366F1"}
                                            />
                                        </linearGradient>
                                    </defs>
                                    <g>
                                        <g>
                                            <path d="M18,4v5H6V4H18z M18,2H6C4.9,2,4,2.9,4,4v5c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M18,15v5H6v-5H18z M18,13H6c-1.1,0-2,0.9-2,2v5c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-5C20,13.9,19.1,13,18,13z" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className={"text"}>
                                <div className={"title"}>
                                    Tasks and Planning
                                </div>
                                <div className={"description"}>
                                    Built in task boards to assign, schedule,
                                    and track tasks
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={`/collaboration/integrations`} tabIndex={-1}>
                        <div className="feature">
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#grad1)"
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                    </g>
                                    <g>
                                        <path d="M10.67,21L10.67,21c-0.35,0-0.62-0.31-0.57-0.66L11,14H7.5c-0.88,0-0.33-0.75-0.31-0.78c1.26-2.23,3.15-5.53,5.65-9.93 c0.1-0.18,0.3-0.29,0.5-0.29h0c0.35,0,0.62,0.31,0.57,0.66L13.01,10h3.51c0.4,0,0.62,0.19,0.4,0.66c-3.29,5.74-5.2,9.09-5.75,10.05 C11.07,20.89,10.88,21,10.67,21z" />
                                    </g>
                                </svg>
                            </div>
                            <div className={"text"}>
                                <div className={"title"}>Integrations</div>
                                <div className={"description"}>
                                    Extend your contact centre with other apps
                                    and services
                                </div>
                            </div>
                        </div>
                    </Link>
                </section>

                <H2 className={"more-banner"}>There's even more too...</H2>
                <section className="more-features">
                    <Link to={`/features/call-recording`} tabIndex={-1}>
                        <div className={"feature"}>
                            <TextButton justify={"space-between"}>
                                Call recording
                                <Chevron />
                            </TextButton>
                        </div>
                    </Link>
                    <Link to={`/features/voicemail`} tabIndex={-1}>
                        <div className={"feature"}>
                            <TextButton justify={"space-between"}>
                                Voicemail
                                <Chevron />
                            </TextButton>
                        </div>
                    </Link>
                    <Link to={`/features/geo-location`} tabIndex={-1}>
                        <div className={"feature"}>
                            <TextButton justify={"space-between"}>
                                Geo-locations
                                <Chevron />
                            </TextButton>
                        </div>
                    </Link>
                    <Link to={`/features/team-chat`} tabIndex={-1}>
                        <div className={"feature"}>
                            <TextButton justify={"space-between"}>
                                App-to-app chat
                                <Chevron />
                            </TextButton>
                        </div>
                    </Link>
                    <Link to={`/features/note-history`} tabIndex={-1}>
                        <div className={"feature"}>
                            <TextButton justify={"space-between"}>
                                Note histories
                                <Chevron />
                            </TextButton>
                        </div>
                    </Link>
                    <Link to={`/features/zapier`} tabIndex={-1}>
                        <div className={"feature"}>
                            <TextButton justify={"space-between"}>
                                Zapier integration
                                <Chevron />
                            </TextButton>
                        </div>
                    </Link>
                    <Link to={`/features/permissions`} tabIndex={-1}>
                        <div className={"feature"}>
                            <TextButton justify={"space-between"}>
                                Permissions
                                <Chevron />
                            </TextButton>
                        </div>
                    </Link>
                    <Link to={`/features/fraud-prevention`} tabIndex={-1}>
                        <div className={"feature"}>
                            <TextButton justify={"space-between"}>
                                Fraud prevention
                                <Chevron />
                            </TextButton>
                        </div>
                    </Link>
                    <Link to={`/features/local-support`} tabIndex={-1}>
                        <div className={"feature"}>
                            <TextButton justify={"space-between"} fullWidth>
                                Local support
                                <Chevron />
                            </TextButton>
                        </div>
                    </Link>
                </section>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default Features
